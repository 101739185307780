import Moment from "moment";

const current_resource_consumption_rule = (product) => {
    /* Calculate resource consumption rule for (selected experience, list of participants) for all timeblocks related to this experience; i.e.
    between start_time and start_time + duration.
    This gives a table booking_resource_consumption that shows quantity for (date, timeblock, resource_id) */
    var resource_id = []
    var product = product
    if(product[0]) {
        product = product[0]
    }
    product.experience_id.resource_consumptionrule_ids.map(rcr => {
        product.absale_product_price.map(pp => {
            if (pp.qty > 0) {
                var check_segment = rcr.segment_ids.some(function(obj){
                    return obj.id === pp.segment_id[0]
                });
                if (check_segment) {
                    resource_id.push({'segment_id': pp.segment_id[0],'resource_id':rcr.resource_id, 'participant_quantity': pp.qty, 'quantity': rcr.quantity})
                }
            }
        })
    })
    return resource_id
}
const totalResurceCapacityUsed = (data, product, timeblock, resource_c) => {
    var total_resource_capacity_used = {}
    var product = product
    if(product[0]) {
        product = product[0]
    }
    product.company_id[2].map(rc => {
        resource_c.filter(obj => obj.resource_id[0] === rc.resource_id && obj.timeblock === timeblock && obj.date === data.date).map(rsc => {
            if (!(rsc.resource_id[0] in total_resource_capacity_used)) {
                total_resource_capacity_used[rsc.resource_id[0]] = rsc.quantity
            } else {
                total_resource_capacity_used[rsc.resource_id[0]] = total_resource_capacity_used[rsc.resource_id[0]] + rsc.quantity
            }
        })
    })
    return total_resource_capacity_used
}

export const enforce_capacity = (data, total_participant, fe, checkinrule, product_fe) => {
    /* Rule 1 Enforce capacity
   True if number of (participants + start_time.participants_count) > start_time.checkin_capacity AND is_available is true */
    if (fe && total_participant > 15) {
        return true
    }
    var total = total_participant + data.checkin_participants
    if (data.has_multiple_course) {
        if (!data.is_available) {
            return false
        }
        let adult_segments = data.checkin_rule.filter(rule => rule.segment_group == 'adult')[0].segment_ids.map(segment => {return segment.id})
        let junior_segments = data.checkin_rule.filter(rule => rule.segment_group == 'junior')[0].segment_ids.map(segment => {return segment.id})
        let total_adult = 0
        let total_junior = 0
        let pax = []
        pax = product_fe.absale_product_price.filter(pp => pp.qty > 0)
        pax.map(value => {
            if (adult_segments.includes(value.segment_id[0])){
                total_adult += value.qty
            }
            else if (junior_segments.includes(value.segment_id[0])){
                total_junior += value.qty
            }
        })
        if ((data.adult_participants_total + total_adult > data.adult_check_in_capacity) && total_adult > 0){
            return false
        }
        if ((data.junior_participants_total + total_junior > data.junior_check_in_capacity) && total_junior > 0){
            return false
        }
    }
    if ((total > data.checkin_capacity && data.is_available) && !data.has_multiple_course) {
        return false
    }
    return true
}

/*
const rule2 = (data, product, timeblock, resource_c, fe) => {
    var can_book = true
    let total_resource_capacity_used = totalResurceCapacityUsed(data, product, timeblock, resource_c)
    var product = product
    if(product[0]) {
        product = product[0]
    }
    product.company_id[2].map(rc => {
        if (can_book) {
            if (rc.override_manager_only === false) {
                 // enfore_resource_standard
                //This rule ensures that resource consumption does not exceed resource capacity for each timeblock, for resources where override_manager_only is false.
                //
                // console.log(total_resource_capacity_used[rc.resource_id], 'Rule3', rc.capacity)
                // For each timeblock, each resource, checks that: booking_resource_consumption[date, timeblock, resource_id] + global_resource_consumption[date, timeblock, resource] <= park.capacity[‘resource_id’]
                let current_resource_ids = current_resource_consumption_rule(product)
                for (let i=0; i<current_resource_ids.length;i++) {
                    if (rc.resource_id === current_resource_ids[i].resource_id) {
                        var current_resource_quantity_used = current_resource_ids[i].participant_quantity * current_resource_ids[i].quantity
                        var total_resource = total_resource_capacity_used[rc.resource_id] + current_resource_quantity_used
                        if (total_resource > rc.capacity) {
                            can_book = false
                            break
                        } else {
                            can_book = true
                        }
                    }
                }
            } else {
                can_book = true
            }
        }
    })
    return can_book
}

const rule3 = (data, product, timeblock, resource_c) => {
    var can_book = true
    let total_resource_capacity_used = totalResurceCapacityUsed(data, product, timeblock, resource_c)
    var product = product
    if(product[0]) {
        product = product[0]
    }
    product.company_id[2].map(rc => {
        if (can_book) {
            if (rc.override_manager_only === true) {
                // enfore_resource_standard
                //This rule ensures that resource consumption does not exceed resource capacity for each timeblock, for resources where override_manager_only is false.
                //
                // For each timeblock, each resource, checks that: booking_resource_consumption[date, timeblock, resource_id] + global_resource_consumption[date, timeblock, resource] <= park.capacity[‘resource_id’]
                let current_resource_ids = current_resource_consumption_rule(product)
                for (let i=0; i<current_resource_ids.length;i++) {
                    if (rc.resource_id === current_resource_ids[i].resource_id) {
                        var current_resource_quantity_used = current_resource_ids[i].participant_quantity * current_resource_ids[i].quantity
                        var total_resource = total_resource_capacity_used[rc.resource_id] + current_resource_quantity_used
                        if (total_resource > rc.capacity) {
                            can_book = false
                            break
                        } else {
                            can_book = true
                        }
                    }
                }
            } else {
                can_book = true
            }
        }
    })
    return can_book
}
*/

const rule23 = (data, product, timeblock, resource_c, override_manager_only) => {
    var can_book = true
    // let total_resource_capacity_used = totalResurceCapacityUsed(data, product, timeblock, resource_c)
    var product = product
    if(product[0]) {
        product = product[0]
    }
    
    var begin_timeblock = timeblock;
    var end_timeblock = timeblock + product.duration;
    while (begin_timeblock < end_timeblock) {
        var total_resource_capacity_used = totalResurceCapacityUsed(data, product, begin_timeblock, resource_c);
        product.company_id[2].map(rc => {
            if (can_book) {
                if (rc.override_manager_only === override_manager_only) {
                    let current_resource_ids = current_resource_consumption_rule(product)
                    for (let i=0; i<current_resource_ids.length;i++) {
                        if (rc.resource_id === current_resource_ids[i].resource_id) {
                            var current_resource_quantity_used = current_resource_ids[i].participant_quantity * current_resource_ids[i].quantity
                            var total_resource = total_resource_capacity_used[rc.resource_id] + current_resource_quantity_used
                            
                            if (total_resource > rc.capacity) {
                                can_book = false
                                break
                            } else {
                                can_book = true
                            }
                        }
                    }
                } else {
                    can_book = true
                }
            }
        })
        if (can_book == false) {
            break
        }
        begin_timeblock += 15;
    }
    return can_book
}

const canBook = (data, product, total_participant, timeblock, cek_onchange, resource_c, fe, product_fe) => {
    var product = product
    if (product_fe) {
        product = product_fe
    }
    let res = true

    if (cek_onchange[1]) {
        res = enforce_capacity(data, total_participant, fe, product.experience_id.checkin_capacity_rule, product_fe)
    }
    if (res && cek_onchange[2]) {
        // Check resouce consumption when override_manager_only is false
        res = rule23(data, product, timeblock, resource_c, false)
    }
    if (res && cek_onchange[3]) {
        // Check resouce consumption when override_manager_only is true
        res = rule23(data, product, timeblock, resource_c, true)
    }
    return res
}

export const startTime = (start_time, checked, cek_onchange, resource_c, value, fe, total_participant_fe, product_fe, second_product_fe, timezone) => {
    var state = {};
    var product = JSON.parse(window.localStorage.getItem('product_data'))
    var total_participant = 0
    if (!fe) {
        product[0].absale_product_price.map(prod => {
            if (prod.qty > 0) {
                total_participant = total_participant + prod.qty
            }
        })
    } else {
        total_participant = total_participant_fe
    }

    state['highlightWithRanges'] = [];
    state['dateAvalaible'] = [];
    state['includeDates'] = [];
    state.includeDates.push(new Date("1000-01-01"));
    let date_month = {}
    start_time.map(d => {
        var t_participant = total_participant + d.checkin_participants
        var timeblock = d.float_time * 60
        
        let can_book = canBook(d, product, total_participant, timeblock, cek_onchange, resource_c, fe, product_fe)
        d['available_booking'] = can_book
        d['status'] = can_book ? "Available" : "Full"
        if (can_book) {
            d['hex_st_color'] = "date_day_green"
            /* if participant <= 50% checkin capacity */
            var capacity_50 = parseInt(d.checkin_capacity / 2)
            if (checked) {
                if (value === 0 || parseInt(value) === 1) {
                    // fe ? total_participant_fe : 0
                    if (t_participant > capacity_50 && t_participant <= d.checkin_capacity){
                        d['hex_st_color'] = "date_day_orange"
                        d['status'] = "Limited"
                    }
                }
            }
        } else {
            d['hex_st_color'] = ""
        }

        // if time is not availablle = False
        if (!d.is_available) {
            d['available_booking'] = d.is_available
            d['status'] = "Full"
            d['hex_st_color'] = ""
        }

        // check if time less than current time
        var current_date = new Date()
        var date = Moment(d.date + " " + d.time);
        if (date < current_date) {
            d['available_booking'] = false
            d['status'] = "Full"
            d['hex_st_color'] = ""
        }

        state.dateAvalaible.push(d)
        if (!(d.date in date_month)) {
            date_month[d.date] = {
                'available': [],
            }
        }
        date_month[d.date]['available'].push(d.available_booking)
        let start_time_date = new Date(d.date)
        let start_time_userTimezoneOffset = start_time_date.getTimezoneOffset() * 60000;
        start_time_date = new Date(start_time_date.getTime() + start_time_userTimezoneOffset);
        //   formatter = new Intl.DateTimeFormat([], options);
          state.includeDates.push(start_time_date);
    })
    state.includeDates.push(new Date("3000-01-01"));
    Object.keys(date_month).map((key, index) => {
        let val = {};

        let date_key = new Date(key)
        let date_key_userTimezoneOffset = date_key.getTimezoneOffset() * 60000;
        date_key = new Date(date_key.getTime() + date_key_userTimezoneOffset);

        let date_array = [new Date(date_key)]
        let array_available = date_month[key].available
        let result = array_available.filter(i => i === false).length
        let available_50_length = array_available.length / 2
        if (result >= array_available.length) {
            val['date_day_red'] = date_array
        } else if (result > available_50_length) {
            val['date_day_orange'] = date_array
        } else {
            val['date_day_green'] = date_array
        }
        state.highlightWithRanges.push(val);
    });
    return state
}

  export const getErrors = (code) => {
    let errorCode = {
        "V6100": "card name",
        "V6101": "card expiry month",
        "V6102": "card expiry year",
        "V6103": "card start month",
        "V6104": "card start year",
        "V6106": "card cvn",
        "V6110": "credit card number",
    };
    return errorCode[code];
  }


  // set interval spinner
  export const numberSpinner = () => {
    // return 20000
    return 40000
  }

// format parameters {'color1': 'value'}
export const colorSetProperty = (colorArgs={}) => {
    const elements = document.querySelector('body');
    for (const c in colorArgs) {
        if (colorArgs.hasOwnProperty(c) && colorArgs[c]) {
            elements.style.setProperty(`--${c}`, colorArgs[c])
        }
    }
}

export const setGoogleFont = (snipFont) => {
    // Set google font
    let elementTag = new DOMParser().parseFromString(snipFont, 'text/html')
    var head = document.getElementsByTagName('head')[0];
    let elementChild = elementTag.head.childNodes;
    for (let i = 0; i < elementChild.length; i++) {
        head.appendChild(elementTag.getElementsByTagName(elementChild[i].nodeName)[0]);
    }
}

export const setHeadTag = (snipheadtag) => {
    //Set Head Tag
    let elementTag = new DOMParser().parseFromString(snipheadtag, 'text/html')
    var head = document.getElementsByTagName('head')[0];
    var elementTag_child = elementTag.head.childNodes;
    for (let index = 0; index < elementTag_child.length; index++) {
        head.appendChild(elementTag.getElementsByTagName(elementTag_child[index].nodeName)[0]);
    }
}

export const setFooter = (snipFooter) => {
    // Set Footer Tag
    let nodes = new DOMParser().parseFromString(snipFooter, 'text/html').body.childNodes;
    let footer = document.getElementById('footer-text');
    while(nodes[0]) {
        footer.append(nodes[0]);
    }
}

export const capitalize = (value) => {
    var textArray = value.split(' ')
    var capitalizedText = ''
    for (var i = 0; i < textArray.length; i++) {
      capitalizedText += textArray[i].charAt(0).toUpperCase() + textArray[i].slice(1) + ' '
    }
    return capitalizedText.trim()
}

export const setTitle = (parkName) => {
    // Set title of the tab
    let head = document.getElementsByTagName('head')[0];
    let name = parkName.toLowerCase();
    let titleName = name.match(/next.*/) ? "Next Level High Ropes Adventure Park" : "Treetops Adventure";

    let titleLeft = document.location.hash.split("/").includes("voucher") ?
                    "Vouchers" : "Bookings";

    let titleTag = `<title>${titleLeft} - ${titleName}</title>`;
    // Set favicon
    let iconUrl = name.match(/next.*/) ?
        "https://nextlevelpark.com.au/wp-content/themes/next-level/library/img/favicon.png?v=4" :
        `${process.env.PUBLIC_URL}/favicon.ico`;
    let iconTag = `<link rel="shortcut icon" href="${iconUrl}" />`;
    // Build title tag
    let elementTag = new DOMParser().parseFromString(titleTag, 'text/html')
    var elementChild = elementTag.head.childNodes;
    // Build favicon tag
    let iconElementTag = new DOMParser().parseFromString(iconTag, 'text/html')
    var iconElementChild = iconElementTag.head.childNodes;
    for (let index = 0; index < elementChild.length; index++) {
        // Set title tag
        head.appendChild(elementTag.getElementsByTagName(elementChild[index].nodeName)[0]);
        // Set favicon tag
        head.appendChild(iconElementTag.getElementsByTagName(iconElementChild[index].nodeName)[0]);
    }
}

export const removeFooter = () => {
    return document.getElementsByTagName('footer')[0] && document.getElementsByTagName('footer')[0].remove();
}


export const unsetFooter = () => {
    return document.getElementsByTagName('footer')[0].innerHTML = '';
}

export const convertToTime = (float) => {
    // Separate the int from the decimal part
    var hour = Math.floor(float);
    var decpart = float - hour;
    var min = 1 / 60;

    // Round to nearest minute
    decpart = min * Math.round(decpart / min);
    var minute = Math.floor(decpart * 60) + '';

    // Add padding if need
    if (minute.length < 2) {
        minute = '0' + minute;
    }

    // Concate hours and minutes
    var time = hour + ':' + minute;

    return time;
}
