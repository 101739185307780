import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchParks } from "../../actions/global";
import ProgressBar from "./helper/progressBar";
import WaiverTypeCard from "./WaiverType/waiverType";
import PersonalInformationCard from "./PersonalInformation/personalInformation";
import EmergencyContactCard from "./EmergencyContact/emergencyContact";
import MinorParticipantCard from "./MinorParticipant/minorParticipant";
import SignWaiverCard from "./SignWaiver/signWaiver";
import Header from "./helper/Header";
import axios from "axios";
import axiosRetry from 'axios-retry';
import { api_get_booking_id, api_create_waiver } from "../../api/api";
import { removeFooter } from "../../helpers/helpers";
import { colorSetProperty, numberSpinner } from "../../helpers/helpers";

let timeSpinner = "";
class Waiver extends Component {
    constructor(props) {
      super(props);
      this.state = {
        WaiverTypeShow: true,
        PersonalInformationShow: false,
        EmergencyContactShow: false,
        MinorParticipantShow: false,
        SignWaiverShow: false,
        park: null,
        progress: 0,
        isLoading: true,
        booking: null,
        customer: null,
        waiverParticipants: null,
        error: null,
        personalInformation: null,
        waiverPersonalInfo: {
          waiverID: null,
          waiverType: null,
          bookingID: null,
          email: null,
          year: null,
          month: null,
          age: null,
          fullName: null,
          gender: null,
          occupation: null,
          country: null,
          state: null,
          suburb: null,
          postcode: null,
          phone: null,
          isEmergencyContact: false,
          emergencyName: null,
          emergencyPhoneNumber: null,
          isMedicalCondition: false,
          subscribe: null,
          medicalConditionReason: null,
          tickbox_rule_ids: [],
          minorParticipants: [],
        },
      };
    }

    ActionShowState = StateName => {
      let state = { ...this.state };
      state = this.ShowState(state, StateName);
      this.setState(state);
    };

    componentWillMount(){
      this.get_booking_id();
    }

    create_waiver = () => {
      let state = { ...this.state };
      const params = {
        jsonrpc: "2.0",
        method: "call",
        params: {data: this.state.waiverPersonalInfo}
      };
      this.setState({error: null})
      axios
        .post(api_create_waiver, params, {
          headers: { "Content-Type": "application/json;charset=utf-8" }
        })
        .then(({ data }) => {
          if (data.result.waiverID) {
            state.waiverPersonalInfo.waiverID = data.result.waiverID
            this.setState({state})
          }
          if (data.result.error) {
            this.setState({error: data.result.error})
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
          }
        })
        .catch(({ err }) => {});
    };

    setProgress(StateName) {
      switch(StateName) {
        case 'WaiverTypeShow':
          return 1
        case 'PersonalInformationShow':
          return 2
        case 'EmergencyContactShow':
          return 3
        case 'MinorParticipantShow':
          return 4
        case 'SignWaiverShow':
          return 5
      }
    }

    addEmergencyContact = value => {
      let state = { ...this.state };

      state.waiverPersonalInfo.emergencyName = value.full_name
      state.waiverPersonalInfo.emergencyPhoneNumber = value.phone

      this.setState(state)

      if (this.state.waiverPersonalInfo.waiverType == "self_only") {
        this.create_waiver()
        this.ActionShowState("SignWaiverShow")
      }
      else {
        this.ActionShowState("MinorParticipantShow")
      }

    }

    saveMinorParticipant = value => {
      let state = { ...this.state };

      state.waiverPersonalInfo.minorParticipants = value
      
      this.setState(state)
      this.create_waiver()
      this.ActionShowState("SignWaiverShow")
    }

    saveWaiver = value => {
      let state = { ...this.state };

      state.waiverPersonalInfo.email = value.email
      state.waiverPersonalInfo.year = value.year
      state.waiverPersonalInfo.month = value.month
      state.waiverPersonalInfo.age = value.age
      state.waiverPersonalInfo.fullName = value.fullName
      state.waiverPersonalInfo.gender = value.gender
      state.waiverPersonalInfo.occupation = value.occupation
      state.waiverPersonalInfo.country = value.country
      state.waiverPersonalInfo.state = value.state
      state.waiverPersonalInfo.suburb = value.suburb
      state.waiverPersonalInfo.postcode = value.postcode
      state.waiverPersonalInfo.phone = value.phone
      state.waiverPersonalInfo.isEmergencyContact = value.isEmergencyContact
      state.waiverPersonalInfo.isMedicalCondition = value.isMedicalCondition
      state.waiverPersonalInfo.medicalConditionReason = value.medicalConditionReason
      state.waiverPersonalInfo.subscribe = value.subscribe
      state.waiverPersonalInfo.bookingID = parseInt(this.props.match.params.booking_id)

      if (this.state.waiverPersonalInfo.waiverType == 'minor_child' && value.isEmergencyContact) {
        state.waiverPersonalInfo.emergencyName = value.fullName
        state.waiverPersonalInfo.emergencyPhoneNumber = value.phone
        this.setState(state)
        this.create_waiver()
        this.ActionShowState("MinorParticipantShow")
      }
      else {
        this.setState(state)
        this.ActionShowState("EmergencyContactShow")
      }

    }

    changeWaiverType = waiverType => {
      let state = { ...this.state };
      state.waiverPersonalInfo.waiverType = waiverType
      this.setState(state)
      this.ActionShowState("PersonalInformationShow")
    }

    ShowState(state, StateName) {
      state.WaiverTypeShow =
        StateName === "WaiverTypeShow" ? true : false;
      state.PersonalInformationShow =
        StateName === "PersonalInformationShow" ? true : false;
      state.EmergencyContactShow =
        StateName === "EmergencyContactShow" ? true : false;
      state.MinorParticipantShow =
        StateName === "MinorParticipantShow" ? true : false;
      state.SignWaiverShow =
        StateName === "SignWaiverShow" ? true : false;

      let progress = this.setProgress(StateName)
      state.progress = progress
      return state;
    }

    get_booking_id = () => {
      // const client = axios.create({ baseURL: api_get_booking_id, headers: {'Content-Type': 'application/json'} });

      // axiosRetry(client, { retries: 45, retryDelay: (retryCount) => {
      //   return retryCount * 750;
      // } });

      let parameters = {
        booking_id: parseInt(this.props.match.params.booking_id)
      };
      
      axios.get(api_get_booking_id, { params: parameters }).then( ({data}) => {
        console.log(data)
        if (data.booking) {
          this.setState({ booking: data.booking })
          this.setState({ customer: data.customer })
          this.setState({ waiverParticipants: data.waiver_participants })
          this.setState({ progress: 1 })
          this.setState({ tickbox_rule_ids: data.tickbox_rule_ids })
          this.toggleLoading(false);
        }
        else {
          // sessionStorage.setItem('error_message', data.error)
          window.location.href = "/#/waiver_kiosk/" +  this.props.match.params.park_id + "/" + this.props.match.params.booking_id + "/expired";
        }
      })
    };
    
    customizeColor(park) {
      // Customize color
      const colors = {
          'color1': park.t_color1,
          'color2': park.t_color2,
          'color3': park.t_color3,
          'color4': park.t_color4,
      }
      console.log(park);
      colorSetProperty(colors);
    }

    componentDidMount() {
      const { fetchParks } = this.props;
      fetchParks();
      console.log('fetch parks')
      removeFooter();
    }

    UNSAFE_componentWillReceiveProps(newProps) {
      let state = { ...this.state };
      if (newProps.parks) {
        newProps.parks.map(park => {
          if (park.id === parseInt(this.props.match.params.park_id) && park.type != "other_company") {
            state.park = park;
            this.customizeColor(park);
          }
        });
      }
      this.setState(state);
    }

    // - spinner -
    spinnerTimeout() {
      timeSpinner = setTimeout(
        function() {
          this.setState({isLoading: false})
        }
        .bind(this),
        numberSpinner()
      );
    }
    stopSpinnerTimout() {
      clearTimeout(timeSpinner)
    }

    // - loading -
    toggleLoading(value) {
      this.setState({ isLoading: value });
      if (value) {
        this.spinnerTimeout()
      } else {
        this.stopSpinnerTimout()
      }
    }

    render() {
      return (  
        <>
          <Header 
            park={this.state.park} 
          />
          <div className={this.state.isLoading === true ? 'loading': ''}></div>
          <div id="waiver" className={`pt-4 pb-4 ${this.state.isLoading === true ? 'loading': ''}`}>
            <div className="container" style={{marginBlock:"20px"}}>
              <div className="waiver-content">
                <ProgressBar 
                  progress={this.state.progress}
                  actionShow={this.ActionShowState}
                />
                <div class="errorTxt fs-subtitle text-center">
                {   this.state.error ?
                    <div class="error">
                        <h2>{this.state.error}</h2>
                    </div>
                    :
                    <></>
                }
                </div>
                <WaiverTypeCard
                  park={this.state.park}
                  booking={this.state.booking}
                  customer={this.state.customer}
                  waiverParticipants={this.state.waiverParticipants}
                  actionShow={this.ActionShowState}
                  WaiverTypeShow={this.state.WaiverTypeShow}
                  changeWaiverType={this.changeWaiverType}
                />
                <PersonalInformationCard 
                  park={this.state.park}
                  actionShow={this.ActionShowState}
                  PersonalInformationShow={this.state.PersonalInformationShow}
                  saveWaiver={this.saveWaiver}
                  waiverType={this.state.waiverPersonalInfo.waiverType}
                  tickbox_rule_ids={this.state.tickbox_rule_ids}
                  booking={this.state.booking}
                />
                <EmergencyContactCard 
                  park={this.state.park}
                  actionShow={this.ActionShowState}
                  EmergencyContactShow={this.state.EmergencyContactShow}
                  isEmergencyContact={this.state.waiverPersonalInfo.isEmergencyContact}
                  addEmergencyContact={this.addEmergencyContact}
                />
                <MinorParticipantCard
                  park={this.state.park}
                  actionShow={this.ActionShowState}
                  MinorParticipantShow={this.state.MinorParticipantShow}
                  saveMinorParticipant={this.saveMinorParticipant}
                  booking={this.state.booking}
                />
                <SignWaiverCard
                  park={this.state.park}
                  actionShow={this.ActionShowState}
                  SignWaiverShow={this.state.SignWaiverShow}
                  waiverID={this.state.waiverPersonalInfo.waiverID}
                  bookingID={this.state.waiverPersonalInfo.bookingID}
                  waiverPersonalInfo={this.state.waiverPersonalInfo}
                  customer={this.state.customer}
                  personalInformation={this.state.personalInformation}
                  toggleLoading={this.toggleLoading.bind(this)}
                />
              </div>
            </div>
          </div>
        </>
      )
    }
}

const mapStateToProps = state => {
    return {
      parks: state.parkReducer.data,
    };
  };
function mapDispatchToProps(dispatch, ownProps) {
return {
    fetchParks: () => dispatch(fetchParks())
};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Waiver);
  