import React, { Component } from "react";
import AbsaleCard from "../AbsaleCard";
import { 
  CardBody,
  Card,
  FormGroup,
  Label,
  Input,
  Col,
  Button
} from "reactstrap";
import "react-credit-cards/lib/styles-compiled.css";
import AfterpayCheckout from "./AfterpayCheckout";
import WindcaveCard from "../step6/WindcaveCard";
import Amex from "../../icon/amex.png"
import Visa from "../../icon/visa.png"
import Mastercard from "../../icon/card.png"
import ApplePay from "../../icon/apple-pay.png"
import GooglePay from "../../icon/google-pay.png"

let hasActive = false;

function shallowEqual(object1, object2) {
  if (object1.length !== object2.length) {
    return false;
  }
  for (let index = 0; index < object1.length; index++) {
      if (object1[index].msg !== object2[index].msg) {
          return false;
      }
  }
  return true;
}

class PaymentProviderCard extends Component {
  state = {
    selectedPayment: "", // Store selected payment method
    checkTerm: false,
    checkSupervision: false,
    terms_and_conditions: "",
    age_and_supervision_requirements: "",
    enable_government_voucher: false,
    checkboxList: [],
    productSelected: null,
    showCheckVoucherModal: false,
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.PaymentCollapse !== this.props.PaymentCollapse) {
      this.setState({ checkTerm: newProps.enableSubmit })
    }
    if (newProps.defaultPayment !== this.props.defaultPayment) {
      if (newProps.defaultPayment == 'windcave') {
        this.setState({ selectedPayment: newProps.defaultPayment })
        this.props.actionWindcave()
      }
    }
    if (newProps.editCount !== this.props.editCount) {
      if (newProps.defaultPayment != 'windcave') {
        this.setState({ selectedPayment: '' })
      }
    }
    let parks = this.props.parks;
    if (parks !== null && parks.length > 0) {
        let park_data = parks.filter(park => park.id == this.props.park_id)[0];
        if (park_data !== undefined) {
          this.setState({
              terms_and_conditions: park_data.terms_and_conditions_link,
              age_and_supervision_requirements: park_data.age_and_supervision_requirements_link,
              enable_government_voucher: park_data.enable_government_voucher
          });
      }
    }
      this.setState({
          productSelected: this.props.productSelected,
          secondActivity: this.props.secondActivity,
      }) 

      let mainActivityTickbox = this.state.productSelected ? this.state.productSelected.experience_id.tickbox_rule_ids : [];
      let secondActivityTickbox = this.state.secondActivity ? this.state.secondActivity.experience_id.tickbox_rule_ids : [];
      let tickboxesFiltered = mainActivityTickbox.concat(secondActivityTickbox)
          .map(item => item.tickbox_message )
          .filter((x, i, a) => a.indexOf(x) == i);
      let newcheckboxList = tickboxesFiltered.map(tb => {
                  return {"msg": tb, isChecked: false}
              })
      
      if (!shallowEqual(this.state.checkboxList, newcheckboxList)) {
          this.setState({
              checkboxList: newcheckboxList,
          }) 
      }
  }

  getError = () => {
    let error = ''
    if (!this.props.error.errorCode) {
      error = this.props.error.errorDetail;
    }
    else {
      error = "(" + this.props.error.errorCode + ") " + this.props.error.errorDetail;
    }
    return error
  }
  
  onAddingItem = (i) => (event) => {
    this.setState((state, props) => {
      state.checkboxList[i].isChecked = !state.checkboxList[i].isChecked;
      return {
        checkboxList: state.checkboxList
      }
    })
  }

  handleCheckbox = (e) => {
    this.setState({ checkTerm: !this.state.checkTerm });
  }

  handleCheckboxSupervision = e => {
    this.setState({ checkSupervision: !this.state.checkSupervision });
  };
  
  getOpenTickBox() {
      let tickboxes = this.state.checkboxList.map((t,i) => {
          return (
              <div key={`${i}-tick-boxes`}>
              <Label check>
                <Input
                  type="checkbox"
                  checked={t.isChecked}
                  onChange={this.onAddingItem(i)}
                />
                <span className="font-color-step5 letterspace">
                  <small className="term-link">
                    {t.msg}
                  </small>
                </span>
              </Label>
              <br />
              </div>
          )
      });

      return (
        <div>
          {tickboxes}
        </div>
    )
  }

  handlePaymentChange = (event) => {
    const selectedPayment = event.target.value;
    this.setState({ selectedPayment });

    // Call parent function to update state
    if (this.props.handlePaymentProvider) {
      this.props.handlePaymentProvider(selectedPayment);
    }
  };

  getCardDetail() {
    if (this.props.PaymentMethodCardCollapse) {
      hasActive = true;
    }

    const headCard = (
      <>
        {
          this.props.remaining_payment > 0 ?
            <React.Fragment>
              <div
                className={
                  hasActive ? "font-weight-bold title" : "font-weight-bold title head-dis"
                }
              >
                Choose a Payment Method
              </div>
            <div className="text-secondary hidden">_</div>
          </React.Fragment>
          :
          <></>
        }
      </>
    );

    const cardCollapse = (
      <>
        <div className="card-payment">
          <FormGroup check style={this.props.type == 'booking_payment' ? {marginLeft: "-25px"} : {}}>
            <Label check>
              <Input type="checkbox" checked={this.state.checkTerm} onChange={e => this.handleCheckbox(e.target.value)} />
              <span className="font-color-step5 letterspace">
                <small className="term-link">
                  I accept{" "}
                  <a href={this.state.terms_and_conditions} target="_blank" rel="noopener noreferrer">
                    the terms and conditions
                  </a>.
                </small>
              </span>
            </Label>
            <br/>
            {
              this.props.type == 'order' ?
              <>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={this.state.checkSupervision}
                    onChange={e => this.handleCheckboxSupervision(e.target.value)}
                  />
                  <span className="font-color-step5 letterspace">
                    <small className="term-link">
                      I have read and understood that there are{" "}
                      <a href={this.state.age_and_supervision_requirements} target="_blank" rel="noopener noreferrer">
                          age and supervision requirements
                      </a> that apply.
                    </small>
                  </span>
                </Label>
              </> 
              : ""
            }
            {/* TODO */}
            { this.props.type == 'order' ? this.getOpenTickBox() : ""}
            
            <div className={this.props.error ? "pay-error-msg" : "no-error"}>
              <b>{this.props.error.errorDetail ? this.getError() : ""}</b>
            </div>
          </FormGroup>    
        </div>
        <Card 
          key="PaymentProvider" 
          style={ 
            (
              (!this.state.checkTerm && (this.props.type == "voucher" || this.props.type == "booking_payment")) || 
              (!(this.state.checkTerm && this.state.checkSupervision && !this.state.checkboxList.map(cb => {
                  return cb.isChecked
              }).includes(false)) && this.props.type == "order") 
            ) 
            
            ? {overflow: "hidden", pointerEvents: "none", opacity: 0.5} : {}}
        >
          <CardBody>
            {
              this.props.remaining_payment > 0 ?
                <FormGroup row tag="fieldset">
                <Col sm={12}>
                  <ul className="list-group">
                    <li className="list-group-item" check>
                      <FormGroup check style={{paddingLeft:'0px'}}>
                        <span className="ml-3">
                          <Label 
                            check
                            style={{width:'100%'}}
                          >
                            <span>
                              <Input
                                type="radio"
                                name="paymentMethod"
                                value="windcave"
                                checked={this.state.selectedPayment === "windcave"}
                                onChange={this.handlePaymentChange}
                              />
                              Credit Card
                              <span className='mr-3' style={{float:"right"}}>
                                <img src={Visa} height={30}/>
                                &nbsp;
                                <img src={Amex} height={30}/>
                                &nbsp;
                                <img src={Mastercard} height={20}/>
                                &nbsp;
                                <img src={GooglePay} height={36}/>
                                &nbsp;
                                {
                                  this.props.isIOS ? 
                                    <img src={ApplePay} height={37}/>
                                    :
                                    <></>
                                }
                              </span>
                            </span>
                          </Label>
                        </span>
                        {
                          this.state.selectedPayment === "windcave" ? 
                          <WindcaveCard
                            cardClass="card-windcave d-inline-block"
                            type="order"
                            productSelected={this.props.productSelected}
                            park_id={this.props.park_id}
                            WindcaveCardCollapse={this.props.WindcaveCardCollapse}
                            isIOS={this.props.isIOS}
                            PaymentCollapse={false}
                            parks_all={this.props.parks_all}
                            data={this.props.data}
                            totalParticipant={this.props.totalParticipant}
                            actionCollapse={this.props.actionCollapse}
                            parks={this.props.parks}
                            action={this.props.action}
                            totalAmount={this.props.remaining_payment}
                            actionWindcave={this.props.actionWindcave}
                            getNewSession={this.props.getNewSession}
                            enableSubmit={this.props.enableSubmit}
                            windcaveLink={this.props.windcaveLink}
                            previousWindcaveLink={this.props.previousWindcaveLink}
                            toggleLoading={this.props.toggleLoading}
                            error={this.props.error}
                          />
                          :
                          <></>
                        }
                      </FormGroup>
                    </li>
                    <li className={"list-group-item " + (this.props.voucher_list.find(x => !x.type.includes('promocode')) ? 'hide-afterpay' : '')}>
                      <FormGroup check  style={{paddingLeft:'0px'}}>
                        <span className="ml-3">
                          <Label check>
                            <span style={{height: this.state.selectedPayment === 'afterpay' ? "64px": "100%"}}>
                              <Input
                                type="radio"
                                name="paymentMethod"
                                value="afterpay"
                                checked={this.state.selectedPayment === "afterpay"}
                                onChange={this.handlePaymentChange}
                                disabled={(this.props.price < this.props.minimum_amount ||
                                  this.props.price > this.props.maximum_amount ||
                                  this.props.voucher_list.find(x => !x.type.includes("promocode")))}
                              />
                              Afterpay
                              <span>
                                {/* &nbsp; <strong><span data-afterpay-modal="en_AU">ⓘ</span></strong> */}
                                {/* <ImageViewerAfterPay /> */}
                                {
                                  this.state.selectedPayment === "afterpay" ? 
                                    <AfterpayCheckout
                                      onComplete={e => this.props.actionAfterpay(e)}
                                      token={this.props.afterpay_token}
                                      behavior={'popup'}
                                      style={{fontSize:'1rem', float:'right'}}
                                      className="mr-4"
                                    />
                                  :
                                  <></>
                                }
                              </span>
                            </span>
                            <afterpay-placement 
                              data-locale="en_AU"
                              data-currency="AUD"
                              data-amount={this.props.remaining_payment}
                              data-size="md"
                              class="mt-0"
                            />
                          </Label>
                        </span>
                          {(this.props.voucher_list.find(x => !x.type.includes('promocode'))) && (
                            <div style={{ color: "red", fontSize: "12px" }}>
                              *Payment doesn't support voucher or government voucher
                            </div>)
                          }
                      </FormGroup>
                    </li>
                  </ul>
                </Col>
              </FormGroup>
              :
              <div>
                <Button
                  type="submit"
                  size="lg"
                  onClick={this.props.action}
                  style={{ fontSize: "1.25rem" }}
                  block
                >
                  Confirm Booking
                </Button>
              </div>
            }
          </CardBody>
        </Card>
      </>
    );

    return { headCard, cardCollapse };
  }

  render() {
    const { cardStyle, numberCircle, cardClass } = this.props;
    const { headCard, cardCollapse } = this.getCardDetail();

    return (
      <AbsaleCard
        cardStyle={cardStyle}
        cardClass={cardClass}
        numberCircle={numberCircle}
        headCard={headCard}
        step="5"
        cardCollapse={cardCollapse}
        collapse={this.props.PaymentMethodCardCollapse}
        data={this.props.data}
        actionCollapse={this.props.actionCollapse}
        name="PaymentMethodCardCollapse"
      />
    );
  }
}

export default PaymentProviderCard;
