import React, { Component, useState } from "react";
import AbsaleCard from "./../AbsaleCard";
import { FaApple } from "react-icons/fa";
import {
  CardBody,
  Card,
  Button,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";

let hasActive = false;
let shake= "";
var shouldToggle = false;

class WindcaveCard extends Component {
  state = {
    hasCheckApproval: false,
    currentWindcaveLink: undefined,
    // checkTerm: false,
    // checkSupervision: false,
    enable_government_voucher:false,
    checkboxList: [],
    productSelected: null,
  };

  componentDidUpdate(prevProps, prevState) {
    var self = this;
    
    //to_be_checked
    if (
      (!self.props.error.errorCode && prevProps.windcaveLink !== self.props.windcaveLink) || 
      (self.props.errorCode && prevProps.windcaveLink != undefined)
    ){
      window.addEventListener('message', function(e) {
        if (e.data.trigger === "approved" && !e.data.success) {
          self.props.action();
          self.setState({
            hasCheckApproval: true,
          })
          e.data.success = true;
        } else if (e.data.trigger === "cancelled" && !e.data.success) {
          self.props.actionWindcave(true);
          self.setState({
            hasCheckApproval: true,
          });
          shouldToggle = false;
          e.data.success = true;
        } else if (e.data.trigger === "declined" && !e.data.success) {
          self.props.actionWindcave(true);
          self.setState({
            hasCheckApproval: true,
          })
          e.data.success = true;
        }
        
        if (e.data.success && prevState.hasCheckApproval) {
          e.data.success = false;
        }
  
      }, false);
  
      if (self.state.hasCheckApproval && prevState.hasCheckApproval) {
          self.setState({
            hasCheckApproval: false,
          })
      }
    }

  }

  hideSpinner = () => {
    if (this.props !== undefined && !shouldToggle) {
      // check if current link is undefined of the same ss
      this.props.toggleLoading(false);
      shouldToggle = false
    } else {
      shouldToggle = true
    }
  }

  getError = () => {
    const error = "(" + this.props.error.errorCode + ") " + this.props.error.errorDetail;
    return error
  }

  render() {
    const { cardStyle, numberCircle, cardClass } = this.props;
    const total_amount = this.props.totalAmount;
    const headCard = this.getheadCard();

    const cardCollapse = (
      <>
        {/* : <></> } */}
        <Card>
          <CardBody className="ml-0 mr-5 cardbody-step3" style={{}}>
                {
                  total_amount > 0 ?
                  <div className="pay_method">
                    <br/>
                    {this.props.isIOS ? 
                    <div className="mb-4 apple-pay-btn" style={{ textAlign: 'center', color: "#262B4D" }}>
                      <h3 className="mb-3" >Pay with</h3>
                      <Button
                        type="submit"
                        size="lg"
                        onClick={this.props.getNewSession}
                        style={{ fontSize: "1.5rem", display: 'inline-block', justifyContent: 'center', alignItems: 'center', borderRadius: '0px', backgroundColor: 'black'}}
                        block
                      >
                        <FaApple style={{ marginBottom: '7px' }}/>Pay
                      </Button>
                    </div>
                    : ''} 

                    <iframe
                      id="responsive-windcave-iframe"
                      className="responsive-iframe"
                      src={this.props.windcaveLink}
                      scrolling="auto"
                      frameBorder="0"
                      onLoad={this.hideSpinner}>
                    </iframe>
                  </div>
                  :
                  <></>
                }
          </CardBody>
        </Card>
      </>
    );
    return (
      // this.props.type == "voucher" ?
      <AbsaleCard
        cardStyle={cardStyle}
        cardClass={cardClass}
        numberCircle={numberCircle}
        headCard={headCard}
        step={this.props.step}
        cardCollapse={cardCollapse}
        collapse={this.props.WindcaveCardCollapse}
        data={this.props.data}
        actionCollapse={this.props.actionCollapse}
        name="WindcaveCardCollapse"
        type={this.props.type}
        parks={this.props.parks_all}
      />
      // : ""
    );
  }

  getheadCard() {
    let detail = [];
    if (!this.props.WindcaveCardCollapse) {
      detail.push(
        <div key={'head-card-1'} className="text-secondary font-weight-bold row col-md-12">
        </div>
      );
    }
    if (this.props.WindcaveCardCollapse) {
      hasActive = true;
    }
    return (
      <React.Fragment>
        {
          this.props.step ?
          <div className={hasActive ? "font-weight-bold title" : "font-weight-bold title head-dis"}>Payment Method</div>
          : ""
        }
        {detail}
      </React.Fragment>
    );
  }
}

export default WindcaveCard;

